<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Essential TCPA Compliance for Insurance Companies</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Insurance Companies</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="services-details-overview">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-desc">
                        <h3>Secure Your Operations with Advanced TCPA Compliance</h3>
                        <p>Ensuring advanced TCPA compliance is paramount for any business operating in industries prone to regulatory scrutiny, such as telemarketing, financial services, and healthcare. Our solutions offer robust features designed to mitigate risks associated with TCPA violations and protect your operations from potential litigation. </p>
                        <p>In summary, securing your operations with advanced TCPA compliance solutions not only protects your business from potential litigation but also enhances operational efficiency and customer trust. Through real-time call screening, comprehensive compliance resources, and expert support, our solutions empower your business to navigate regulatory complexities confidently while maintaining compliance with industry standards.</p>
                        <!-- <div class="features-text">
                            <h4><i class="flaticon-check-mark"></i> Core Development</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div>

                        <div class="features-text">
                            <h4><i class="flaticon-check-mark"></i> Define Your Choices</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                        </div> -->
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-image">
                        <img src="assets/img/services-details/tcpa_compliance.png" alt="image">
                    </div>
                </div>
            </div>
        </div> 
    </div>

    
</section>
<section class="productive-section  ">
    <div class="container">
        <div class="productive-title">
            <!-- <span>Why Our services</span> -->
            <h3>Key Features</h3>
            <!-- <p>Our Litigation Firewall® enables real-time call screening, ensuring compliance with TCPA regulations and reducing the risk of litigation by blocking non-compliant calls before they reach your agents</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class=" "><img src="assets/img/icon/incoming-call.png" alt="" height="40px" width="40px" srcset=""></i>
                    </div>
                    <h3>Real-time Call Screening</h3>
                    <p>Our Litigation Firewall® and API offer real-time call screening, enabling you to identify and block high-risk callers instantly, thus safeguarding your business from potential TCPA lawsuits.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class=" "><img src="assets/img/icon/mitigation.png" alt="" height="40px" width="40px" srcset=""></i>
                    </div>
                    <h3>Comprehensive Risk Mitigation</h3>
                    <p>With advanced compliance tools, significantly reduce the likelihood of TCPA violations and litigation, ensuring your business operates within legal boundaries</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class=" "><img src="assets/img/icon/training.png" alt="" height="40px" width="40px" srcset=""></i>
                    </div>
                    <h3>Compliance Training</h3>
                    <p>Utilize our online platform to train your agents on TCPA regulations, equipping them with the necessary knowledge to maintain compliance in their daily operations.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-ruler"></i>
                    </div>
                    <h3>Customizable Integration</h3>
                    <p>Our solutions are designed to integrate seamlessly with your existing systems, allowing for smooth implementation without disrupting your current operations.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class=" "><img src="assets/img/icon/expert.png" alt="" height="40px" width="40px" srcset=""></i>
                    </div>
                    <h3>Expert Support</h3>
                    <p>Our team of TCPA compliance experts is available to provide guidance and support, helping you navigate the complexities of TCPA regulations and ensuring your business stays compliant.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-unlink"></i>
                    </div>
                    <h3>Pro Building Services</h3>
                    <p>Our solutions are designed to integrate seamlessly with your existing contact center systems, ensuring smooth implementation and minimal disruption to your operations.</p>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3 style="font-size: 40px  ;">Get Started </h3>
            <h3 style="font-size: 40px  ;">Get Protected</h3>
            <div class="py-3 ">
                <a routerLink="/contact" class="schedule-button" >Schedule a Demo</a>
            </div>
        </div>
        <!-- <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form> -->
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>




<!-- 
<hr>
<div class="container my-5">
    <div class="row">
        <div class="text-content col-lg-10 col-md-12">
            <h1>Get Started Today</h1>
            <p>Put TCPA litigation worries to rest and start sleeping better at night.</p>
        </div>
  
        <div class="button-container col-lg-2 col-md-12 my-5">
 
            <a routerLink="/contact" class="schedule-button">Schedule a Demo</a>
        </div>
    </div>
</div> -->