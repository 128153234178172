<app-navbar-two></app-navbar-two>

<div class="marketing-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="hero-content">
                            <span>Lorem ipsum dolor sit.</span>
                            <h1>YOUR DEFENSE AGAINST TCPA LITIGATION</h1>
                            <p>COMPLETE COMPLIANCE SOLUTIONS TO PROTECT YOUR COMMUNICATIONS</p>

                            <div class="marketing-btn">
                                <a routerLink="/contact" class="marketing-btn-one">BOOK A FREE DEMO</a>
                                <a href="https://www.youtube.com/watch?v=vq-02O0b5pU" class="video-btn popup-youtube"><span>
                                    <i class="fa fa-play"></i></span>
                                    Watch The Demo
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape6.png" alt="image"></div>
</div>

<section class="top-services-section pb-100">
    <div class="container">
        <div class="top-services-area">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img src="assets/img/top-services/top-services1.png" alt="image">
                        <h3>legal Support</h3>
                        <p>A team of dedicated professionals is always ready to offer the assistance you need.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img src="assets/img/top-services/top-services2.png" alt="image">
                        <h3>Compliance Resources</h3>
                        <p>An efficient compliance training platform designed to remotely educate both management and staff.</p>
                    </div>
                </div>
                
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img src="assets/img/top-services/top-services3.png" alt="image">
                        <h3>litigation Firewall</h3>
                        <p>A robust API that scrubs DNC data in real time, tailored to your specific risk tolerance.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="top-services-content">
                        <img src="assets/img/top-services/top-services4.png" alt="image">
                        <h3>Data Optimisation</h3>
                        <p>The API provides high-level data such as carrier information, wireless or landline status, and ported status.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <h3>ROBUST TCPA COMPLIANCE SOLUTIONS FOR CONTACT CENTERS</h3>
                    <span>Secure Your Contact Center with TCPA Compliance</span>
                    <!-- <p>Secure Your Contact Center with TCPA Compliance</p> -->
                    <p>Ensure your contact center adheres to TCPA regulations with our robust compliance solutions. Our services include real-time DNC data scrubbing, comprehensive training for staff, and high-level data APIs that deliver critical information like carrier status and ported numbers. Protect your business from litigation risks and maintain seamless operations with our expert support and advanced compliance tools.</p>

                    <div class="we-btn">
                        <a routerLink="/contact-center" class="we-btn-one">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/robost_tcpa.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/comprehensive_risk.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <h3>COMPREHENSIVE RISK MANAGEMENT SOLUTIONS FOR VOIP CARRIERS</h3>
                    <span>Optimize Your VoIP Operations with Risk Management Solutions</span>
                    <p>Protect your VoIP carrier services with our comprehensive risk management solutions. We offer real-time DNC data scrubbing, advanced compliance training, and a robust API delivering critical data on carrier status and ported numbers. Our dedicated team ensures your business remains compliant with regulations, minimizing litigation risks and maintaining seamless communication. Enhance your VoIP operations with our expert support and innovative compliance tools.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p> -->

                    <div class="we-btn">
                        <a routerLink="/voip-carriers" class="we-btn-one">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <h3>ESSENTIAL TCPA COMPLIANCE FOR INSURANCE COMPANIES</h3>
                    <span>Ensure Compliance and Minimize Risks for Insurance Companies</span>
                    <p>Safeguard your insurance business with our essential TCPA compliance solutions. Our services include real-time DNC data scrubbing, thorough compliance training for your team, and advanced APIs delivering critical information like carrier status and ported numbers. Stay compliant with TCPA regulations, reduce litigation risks, and ensure smooth communication operations. Benefit from our expert support and innovative tools tailored specifically for the insurance industry.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p> -->

                    <div class="we-btn">
                        <a routerLink="/insurance-companies" class="we-btn-one">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/essential_tcpa.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/digital_marketers.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <h3>COMPREHENSIVE TCPA COMPLIANCE FOR DIGITAL MARKETERS</h3>
                    <span>Enhance Digital Marketing with Comprehensive TCPA Compliance</span>
                    <p>Protect your digital marketing campaigns with our extensive TCPA compliance solutions. We offer real-time DNC data scrubbing, effective compliance training, and advanced APIs that provide crucial data such as carrier status and ported numbers. Ensure your marketing efforts adhere to TCPA regulations, minimize litigation risks, and maintain efficient communication operations. Rely on our expertise and innovative tools to keep your digital marketing compliant and effective.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p> -->

                    <div class="we-btn">
                        <a routerLink="/digital-marketers" class="we-btn-one">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <h3>AI AND AUTOMATION: NAVIGATING TCPA COMPLIANCE IN THE NEW ERA</h3>
                    <span>Master TCPA Compliance for AI and Automation</span>
                    <p>Stay ahead in the new era of AI and automation with our comprehensive TCPA compliance solutions. Our services include real-time DNC data scrubbing, specialized compliance training, and advanced APIs delivering vital data like carrier status and ported numbers. Ensure your AI-driven operations adhere to TCPA regulations, reduce litigation risks, and maintain efficient communication. Leverage our expertise and cutting-edge tools to navigate TCPA compliance seamlessly in the age of automation.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p> -->

                    <div class="we-btn">
                        <a routerLink="/ai-and-automation" class="we-btn-one">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/ai_and_automation.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <section class="achieved-section ptb-100">
    <div class="container">
        <div class="achieved-title">
            <span>Why people know us</span>
            <h3>We have Achieved 150+ projects</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolet, consectetur adipiscing  seore suspendisse ultrices gravir.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="16">00</span><span class="sign-icon">k</span></h3>
                    <p>Expert Employee</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="130">00</span><span class="sign-icon">+</span></h3>
                    <p>Ultimate Growth</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="20">00</span><span class="sign-icon">k</span></h3>
                    <p>Global Customer</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <h3><span class="odometer" data-count="210">00</span><span class="sign-icon">+</span></h3>
                    <p>Expert Employee</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="history-section ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 pl-0">
                <div class="history-image">
                    <img src="assets/img/history.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-content-area">
                    <span>History  Behind us</span>
                    <h3>Modern solutions & different Live experience during the working years</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation e  inventore veritatis et quasi architecto beatae</p>
                    <div class="history-btn">
                        <a routerLink="/" class="history-btn-one">Discover More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="performance-section ptb-100">
    <div class="container">
        <div class="performance-title">
            <span>Our Performance</span>
            <h3>We have enormous performance in our history of the journey</h3>
        </div>

        <div class=row>
            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=70></div>
                    <h3>Visualizing is a real-time growth</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=50></div>
                    <h3>Generation of revenue For people</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6">
                <div class="single-performance">
                    <div class=circlechart data-percentage=90></div>
                    <h3>Performance growth by peoples</h3>
                </div>
            </div>

            <div class="col-6 col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-0">
                <div class="single-performance">
                    <div class=circlechart data-percentage=67></div>
                    <h3>A realtime partnership Management</h3>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="productive-section">
    <div class="container">
        <div class="productive-title">
            <span>Why Our services</span>
            <h3>Our Productive Services is Ready</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolet, consectetur adipiscing  seore suspendisse ultrices gravir.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-headphones"></i>
                    </div>
                    <h3>Customer Assistance</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-tag"></i>
                    </div>
                    <h3>Affordable Prices</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3>Global Presence</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-innovation"></i>
                    </div>
                    <h3>Innovative Ideas</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-content"></i>
                    </div>
                    <h3>Content Marketing</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-unlink"></i>
                    </div>
                    <h3>Pro Building Services</h3>
                    <p>Lorem ipsum dolor sitamet cosectetur adipising elit sed do eiusmod tempor.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="needs-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="needs-area-content">
                    <span>What We do</span>
                    <h3>Your business agency needs a goal Our agency helps to achieve in the way</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia.</p>
                    <div class="needs-text">
                        <p><b>8.93</b> (Loremips) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum dolor si Ut enim ad minim venia.</p>
                    </div>
                    <div class="needs-text">
                        <p><b>1.9</b> (important) um dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum dolor si Ut enim ad minim venia.</p>
                    </div>
                    <div class="needs-btn">
                        <a routerLink="/" class="needs-btn-one">Discover More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="needs">
                    <img src="assets/img/needs.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="project-section pb-70">
    <div class="container">
        <div class="project-title">
            <span>Our Project Gallery</span>
            <h3>Meet our work experience from customers</h3>
            <div class="project-btn"><a routerLink="/projects-details" class="project-btn-one">Visit Gallery</a></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-project-item">
                    <img src="assets/img/project/project-img1.png" alt="image">

                    <div class="project-content">
                        <h3><a href="/projects-details">SEO Consultancy</a></h3>
                        <p>20, Deceember 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-project-item">
                    <img src="assets/img/project/project-img2.png" alt="image">

                    <div class="project-content">
                        <h3><a href="/projects-details">Digital Marketing</a></h3>
                        <p>20, Deceember 2024</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-project-item">
                    <img src="assets/img/project/project-img3.png" alt="image">

                    <div class="project-content">
                        <h3><a href="/projects-details">Social Media Marketing</a></h3>
                        <p>20, Deceember 2024</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="saying-section ptb-100">
    <div class="container">
        <div class="saying-title">
            <h3>What is saying clients about us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolet, consectetur adipiscing  seore suspendisse ultrices gravir.</p>
        </div>

        <div class="saying-slides owl-carousel owl-theme">
            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img1.jpg" alt="image">
                    </div>

                    <h4>Ketax Andon</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img2.jpg" alt="image">
                    </div>
                    <h4>Kolil Ward</h4>
                    <span>CEO Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img3.jpg" alt="image">
                    </div>
                    <h4>Jandhu Dew</h4>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>

                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img4.jpg" alt="image">
                    </div>
                    <h4>Andon Smait</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="faq-section ptb-100">
    <div class="container">
        <div class="faq-title">
            <span>Asked Question</span>
            <h3>Frequently Ask Question</h3>
        </div>

        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class="fa fa-plus"></i> A satisfied customer is the best business strategies</a>

                    <p class="accordion-content show">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> We challenge everything</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Cooperation is our best policy</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class="fa fa-plus"></i> Read the most popular Medical News & Article?</a>

                    <p class="accordion-content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</section> -->



<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3 style="font-size: 40px  ;">Get Started </h3>
            <h3 style="font-size: 40px  ;">Get Protected</h3>
            <div class="py-3 ">
                <a routerLink="/contact" class="schedule-button" >Schedule a Demo</a>
            </div>
        </div>
        <!-- <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form> -->
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>


<!-- <section class="provide-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="provide-image">
                    <img src="assets/img/provide.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="provide-area-content">
                    <h3>How to provide service to the customer</h3>
                    <p>Note: Please call us from 12 pm to 8 am. otherwise, our customer supporter will not available to reach your call, but you can drop a mail anytime.</p>

                    <form class="provide-form">
                        <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required>
                        <button type="submit">Submit Questions</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <hr> -->
<!-- <div class="container">
    <div class="row">
        <div class="text-content col-lg-10 col-md-12">
            <h1>Get Started Today</h1>
            <p>Put TCPA litigation worries to rest and start sleeping better at night.</p>
        </div>
  
        <div class="button-container col-lg-2 col-md-12">
            
            <a href="" class="schedule-button" >Schedule a Demo</a>
        </div>
    </div>
</div> -->
