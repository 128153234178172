import { Component } from '@angular/core';

@Component({
  selector: 'app-vopi-carriers',
  // standalone: true,
  // imports: [],
  templateUrl: './vopi-carriers.component.html',
  styleUrl: './vopi-carriers.component.scss'
})
export class VopiCarriersComponent {

}
