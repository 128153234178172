<footer class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-area-content">
                    <!-- <div class="single-footer-widget">
                        <h3>About us</h3>
                    </div> -->
                    <img src="assets/img/logo/blacklistlookup-logo2.png" alt="image">
                    <p>At Blacklist Lookup, we aim to empower our users with the tools and information needed to identify and resolve blacklist issues promptly. We understand the critical importance of maintaining a positive online reputation and are committed to delivering top-notch service and support.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/services">Litigation Firewall</a></li>
                        <li><a routerLink="/services">Legal Support</a></li>
                        <li><a routerLink="/services">Data Optimisation</a></li>
                        <li><a routerLink="/services">Compliance Resources</a></li>
                        <!-- <li><a routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Solution</h3>
 
                    <ul class="footer-quick-links">
                        <li><a routerLink="/contact-center">Contact Centers</a></li>
                        <li><a routerLink="/insurance-companies">Insurance Companies</a></li>
                        <li><a routerLink="/voip-carriers">VOIP Carriers</a></li>
                        <li><a routerLink="/digital-marketers">Digital Marketing</a></li>
                        <li><a routerLink="/ai-and-automation">AI and Automation</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Our Information</h3>

                    <ul class="footer-quick-links address-link">
                        <!-- <li><a href="#"><i class="fa fa-map-marker"></i> 121 King St, VIC 3000, Australia</a></li> -->
                        <li><a href="mailto:info&#64;blacklistlookup.ai"><i class="fa fa-envelope"></i>info&#64;blacklistlookup.ai</a></li>
                        <li><a href="tel:19549900053"><i class="fa fa-phone"></i> +1 9549900053</a></li>
                    </ul>

                    <!-- <ul class="social-list">
                        <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul> -->
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Copyright 2024 DNC Killer, Ltd. | All Rights Reserved<a href="" target="_blank"></a></p>
                </div>

                <!-- <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">  
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <!-- <img src="assets/img/logo/logo.png" alt="Logo"> -->
                        </a>
                    </h2>
                </div>

                <!-- <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Welcome To Ervy</h3>
                        <img src="assets/img/about.jpg" alt="">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolore saepe exercitationem alias unde, blanditiis non error</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-map-marker"></i>
                                Address
                                <span>121 King St, VIC 3000, Australia</span>
                            </li>

                            <li>
                                <i class="fa fa-phone"></i>
                                Email
                                <span>hello&#64;ervy.com</span>
                            </li>

                            <li>
                                <i class="fa fa-envelope"></i>
                                Phone
                                <span>+098-123-23455</span>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>