import { Component } from '@angular/core';

@Component({
  selector: 'app-digital-marketers',
  // standalone: true,
  // imports: [],
  templateUrl: './digital-marketers.component.html',
  styleUrl: './digital-marketers.component.scss'
})
export class DigitalMarketersComponent {

}
