<app-navbar-two></app-navbar-two>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="circulate-image">
                    <img src="assets/img/aboutus.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>About us</span>
                    <h3>Ensuring Your Digital Presence Remains Clean and Trustworthy</h3>
                    <p>Welcome to Blacklist Lookup, your trusted resource for checking and managing online blacklists. Our mission is to provide comprehensive and accurate information to help individuals and businesses ensure their digital presence remains clean and trustworthy.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia.</p> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <span>What We do</span>
                    <h3>Comprehensive Blacklist Monitoring and Reporting Services</h3>
                    <p>We offer a reliable and user-friendly platform to check your status across various blacklists. Whether you are concerned about email blacklists, domain blacklists, or IP blacklists, our service provides quick and detailed reports to help you take necessary action.</p>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia.</p> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/img/blacklist_monitoring.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>





<section class="achieved-section ptb-100">
    <div class="container">
        <div class="achieved-title">
            <span>Why Choose us</span>
            <h3>We are dedicated to maintaining the highest standards of accuracy, reliability, and customer satisfaction. Your trust is our priority, and we continually strive to improve our services to meet your needs.</h3>
            <!-- <p>We are dedicated to maintaining the highest standards of accuracy, reliability, and customer satisfaction. Your trust is our priority, and we continually strive to improve our services to meet your needs.</p> -->
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <!-- <h3>Comprehensive Coverage</h3> -->
                    <p style="text-align: left;"><b style="color: #150346;">Comprehensive Coverage</b>We monitor a wide range of blacklists to ensure you get complete insights.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <!-- <h3>Accurate Results</h3> -->
                    <p style="text-align: left;"><b style="color: #150346;">Accurate Results:</b>Our advanced technology ensures precise and up-to-date information.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <!-- <h3>User-Friendly Interface</h3> -->
                    <p style="text-align: left;"><b style="color: #150346;">User-Friendly Interface:</b>Easily navigate our platform to obtain the information you need.</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="fun-facts-content">
                    <!-- <h3>Expert Support</h3> -->
                    <p style="text-align: left;"><b style="color: #150346;">Expert Support:</b>Our team of experts is always ready to assist you with any questions or concerns.
                        Our Commitment</p>
                </div>
            </div>
        </div>
    </div>
</section>





<!-- <section class="saying-section ptb-100">
    <div class="container">
        <div class="saying-title">
            <h3>What is saying clients about us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolet, consectetur adipiscing  seore suspendisse ultrices gravir.</p>
        </div>

        <div class="saying-slides owl-carousel owl-theme">
            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img1.jpg" alt="image">
                    </div>
                    <h4>Ketax Andon</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img2.jpg" alt="image">
                    </div>
                    <h4>Kolil Ward</h4>
                    <span>CEO Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img3.jpg" alt="image">
                    </div>
                    <h4>Jandhu Dew</h4>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/img/say-image/say-img4.jpg" alt="image">
                    </div>
                    <h4>Andon Smait</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-section inner-team ptb-100">
    <div class="container">
        <div class="team-title">
            <span>Our Team Members</span>
            <h3>Executive Team Support</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/team1.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">White Amlington</a></h3>
                        <span>Manager Logistic</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/team2.jpg" alt="image">
                    </div>
                    
                    <div class="member-content">
                        <h3><a routerLink="/">Agaton Ronald</a></h3>
                        <span>Scientist Ecology</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/team3.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">Alex Wood</a></h3>
                        <span>Scientist Economic</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/team4.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">Mexwell White</a></h3>
                        <span>Owner & CEO of Economic</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="100">00</span>
                        <span class="sign-icon">%</span>
                    </h3>
                    <p>Completed projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="253">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Customer satisfaction</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="24">00</span>
                        <span class="sign-icon">h</span>
                    </h3>
                    <p>Expert Support Team</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="78">00</span>
                        <span class="sign-icon">k</span>
                    </h3>
                    <p>Sales in Count</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div class="partner-section ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partnar/partner-img1.png" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partnar/partner-img2.png" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partnar/partner-img3.png" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partnar/partner-img4.png" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="assets/img/partnar/partner-img5.png" alt="partner">
                </a>
            </div>
        </div>
    </div>
</div> -->




<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3 style="font-size: 40px  ;">Get Started </h3>
            <h3 style="font-size: 40px  ;">Get Protected</h3>
            <div class="py-3 ">
                <a routerLink="/contact" class="schedule-button" >Schedule a Demo</a>
            </div>
        </div>
        <!-- <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form> -->
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section>



<!-- <section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>
        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/img/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/img/shape/shape20.png" alt="Images"></div>
</section> -->